.cart {
  overflow: hidden;
}
.cart_wrapper {
  display: flex;
  flex-direction: column;
  color: #000;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b6b6b6;
  }


  li {
    padding: 10px;

    margin: 0 !important;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #d9d9d9;

    &.cont{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: "DejaVu Sans", sans-serif;
      justify-content: space-between !important;

      .img-part{
        margin: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        min-width: 50px;
        overflow: hidden;
        border-radius: 5px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .info-part{
        padding: 0 10px;
        width: calc(100% - 140px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        span{
          width: 100%;
          display: block;
          text-align: left;
          &:first-child{
            height: 28px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            line-height: 14px;
            flex-direction: row;
            align-items: center;
            word-break: break-word;
            justify-content: flex-start;
          }
          &:last-child {
            color: #fda432;
            padding-top: 10px;
            line-height: 10px;
            font-weight: 800;
          }
        }
      }
      .act-part{
        width: 90px;
        .act-btns{
          margin: 0;
          width: 100%;
          height: 25px;
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: center;
          span {
            display: flex;
            min-width: 25px;
            cursor: pointer;
            font-weight: 800;
            border-radius: 3px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: 1px solid #b7b7b7;
            &:nth-child(2){
              border: 0;
              min-width: 40px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
        .remove-btn{
          opacity: .4;
          line-height: 4px;
          cursor: pointer;
          font-size: 12px;
          font-weight: 800;
          color: indianred;
          text-align: right;
          margin: 0 0 10px 0;
          &:hover{
            opacity: .7;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .cart-header {
    top:0;
    z-index: 5;
    border-top: 0;
    font-size: 16px;
    position: sticky;
    background: #ffffff;
    justify-content: center;
    font-family: "BPG Arial Caps", sans-serif;
    filter: drop-shadow(0px 2px 10px rgba(0,0,0,0.1));
    img {margin-right: 10px}
  }
  .cart-footer {
    all:unset;
    bottom: 0;
    z-index: 5;
    border-bottom: 0;
    position: sticky;
    background: #f8f8f8;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    //box-shadow: 0 0 10px #00000082;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 10px !important;
    .cf_money {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 14px;
      p {
        flex:1;
        margin: 0;
        text-align: left;
      }
      span {
        font-weight: bold;
      }
    }
    .cf_calc {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 16px;
      border-top:1px dashed #ccc;
      padding-top: 10px;
      margin-top: 10px;
      font-family: "BPG Arial Caps", sans-serif;
      font-weight: bold;
      p {
        flex:1;
        margin: 0;
        text-align: left;
      }
      span {
        font-weight: bold;
      }
    }
    .cf_button {
      //margin: 10px 10px 0;
      margin-top: 15px;
      background-color: #fda432;
      font-family: "BPG Arial Caps", sans-serif;
      padding: 10px 20px;
      border-radius: 5px;
      width: 100%;
      &:hover {
        cursor: pointer;
        background-color: #00a850;
        color: white;
      }
    }
    .cf_clear {
      //margin: 10px 10px 0;
      text-align: center;
      margin-top: 15px;
      color:#fda432;
      font-family: "BPG Arial Caps", sans-serif;
      padding: 10px 20px;
      border-radius: 5px;
      width: 100%;
      &:hover {
        cursor: pointer;
        color: #f69b27
      }
    }
  }
}
