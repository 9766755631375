.offerCards-container {
  background-color: #f7f2e2;

  transition: background .3s, border .3s,border-radius .3s,box-shadow .3s;
  margin-top: 0;
  margin-bottom: 100px;

  padding: 70px 15px;

  width: 100%;
  left: 0;

  ul {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    li {
      flex: 1;
      margin: 15px;
      background: #fff;
      border-radius: 5px 5px 5px 5px;

      min-height: 180px;
      max-width: 450px;
      text-align: left;
      padding: 35px 30px 30px 40px;

      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;

      position: relative;

      .img-element {
        position: absolute;
        right: 0;
        top: 30px;
        width: 50%;
        z-index: 1;
        img {
          position: absolute;
          z-index: -1;
          height: auto;
          max-width: 100%;
          border: none;
          -webkit-border-radius: 0;
          border-radius: 0;
          -webkit-box-shadow: none;
          box-shadow: none;

          -ms-transition: all .3s ease;
          -o-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -webkit-transition: all .3s ease;
          transition: all .3s ease;
        }
        .image-behind {
          img {
            position: relative;
            top: 15px;
            right: 25px;
          }
        }
      }
      h2 {
        margin: 0;
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 10px;
        color: #1e1d23;
      }
      .description {
        font-size: 12px;
        text-transform: uppercase;
        line-height: 20px;
        text-shadow: 1px 1px 10px #fff;
        margin-bottom: 15px;
        color: #1e1d23;
      }
      .prize {
        font-size: 20px;
        font-weight: 700;
        color: #00a850;
        background-color: #02010100;
        padding: 0;
      }

      &.bg {
        background: url('../../assets/img/h1_banner2.jpg');
        background-size: cover;
        h2, .description {color: #fff;}
        .prize {color: #ffc222;font-weight: bold;}
      }

      &:hover {
        .img-element {

            .yellow-sircle {
              transform: scale(1.2);

            }

        }
      }
    }
  }

}

@media (max-width: 1200px) {
  .offerCards-container {
    ul {
      li {
        flex: 0 0 50%;
      }
    }
  }
}

@media (max-width: 870px) {
  .offerCards-container {
    ul {
      li {
        flex: 0 0 100%;
      }
    }
  }
}