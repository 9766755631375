

  .product-list-container {
    ul {
      margin: 0 auto;
      clear: both;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      li {
        padding-left: 15px;
        padding-right: 15px;
        list-style: none;
        margin-bottom: 2.4em;
        position: relative;
        transition: all .4s ease-in-out;
        width: 100%;

        flex: 0 0 25%;
        max-width: 25%;


        .product-block {
          position: relative;
          transition: all .4s ease-in-out;
          height: 100%;

          overflow: hidden;
          border: 1px solid;
          border-color: #e5e5e5;
          border-radius: 30px;
          padding: 10px;
          padding-bottom: 25px;
          background: #fff;

          .onsale {
            color: #fff;
            padding: 0 10px;
            font-size: 13px;
            font-weight: 700;
            line-height: 24px;
            display: inline-block;
            position: absolute;
            top: 20px;
            left: 20px;
            background: #1e1d23;
            z-index: 3;
            border-radius: 20px;
          }
          .product-transition {
            display: block;
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;
            button {
              position: absolute;
              right: 0;
              top: 0;
              margin-top: 0;
              z-index: 4;
              white-space: nowrap;
              width: 45px;
              height: 45px;
              padding: 0;
              border: 0;
              font-size: 0;
              background: 0 0!important;
              border-radius: 0;
            }
            .product-image {
              position: relative;
              z-index: 2;
              aspect-ratio: 1;
              img {
                display: block;
                margin: 0 auto;
                width: 100%;

                transform: scale(.95);
                transition: all ease .35s;
                border-radius: 20px;

                height: auto;
                max-width: 100%;
                border: none;
              }

              &:after {
                content: '';
                width: 100%;
                height: 50%;
                border-radius: 20px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #ffc222;
                opacity: .1;
                transition: all ease .35s;
                z-index: -1;
              }
            }
          }
          .product-caption {
            padding: 10px;
            display: inline-block;
            width: 100%;
            h3 {
              font-size: 18px;
              font-weight: 700;
              line-height: 1.3;
              margin: 0;
              margin-bottom: 6px;
              font-family: gilroy,helveticaneue-light,helvetica neue light,helvetica neue,Helvetica,Arial,lucida grande,sans-serif;
              a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: #000;
                text-decoration: none;
              }
              &:hover {
                cursor: pointer;
                a {
                  color: #ffc222;
                }

              }
            }
            .short-description {
              color: #808080;
              font-family: gilroy,helveticaneue-light,helvetica neue light,helvetica neue,Helvetica,Arial,lucida grande,sans-serif;
              text-rendering: optimizeLegibility;

              font-size: 14px;
              line-height: 1.5;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: 3em;
              margin-bottom: 10px;
            }



          }
          .price {
            //font-size: .85em;
            font-weight: 700;
            font-size: 20px;
            color: #999999;
            del {color: #999999;}

            .last-price {
              margin-left: 0.7em;
              color: #ffc222;
            }


          }

        }

        &:hover {

          .product-block {
            //cursor: pointer;
            .product-transition {
              .product-image {
                img {
                  transform: scale(1);
                }
                &:after {
                  opacity: 1;
                  height: 100%;
                }
              }
            }
          }
        }


      }
    }



  }

  .all-delish {
    text-align: center;
    padding: 20px;

    a {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1em;
      color: #1e1d23;
      background-color: #ffc222;
      border-radius: 8px 8px 8px 8px;
      padding: 20px 45px;
      text-decoration: none;
      display: inline-block;
      &:hover {
        color: #fff;
        background-color: #eeac00;
      }
    }
  }

  .favorite_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .card-container {
      flex: 1 calc(25% - 10px);
      max-width: calc(25% - 10px);
      height: auto;
      margin: 5px;
    }
  }


  @media (max-width: 991px) {
    .favorite_wrapper {
      .card-container {
        flex: 1 calc(33.3333% - 10px);
        max-width: calc(33.3333% - 10px);
      }
    }
  }
  @media (max-width: 767px) {
    .favorite_wrapper {
      .card-container {
        flex: 1 calc(50% - 10px);
        max-width: calc(50% - 10px);
      }
    }
  }
  @media (max-width: 500px) {
    .card-container .product-block .product-transition .product-image {
      height: 28vw !important;
    }
  }
  @media (max-width: 400px) {
    .card-container .product-block .product-transition .product-image {
      height: 50vw !important;
    }
    .favorite_wrapper {
      .card-container {
        flex: 1 calc(100% - 10px);
        max-width: calc(100% - 10px);
      }
    }
  }

@media (max-width: 1000px) {

    .product-list-container {
      ul {
        li {
          flex: 0 0 33%;
          max-width: unset;
        }
      }

  }
}

@media (max-width: 750px) {

    .product-list-container {
      ul {
        li {
          flex: 0 0 50%;
          max-width: unset;
        }
      }
    }

}
@media (max-width: 500px) {
    .product-list-container {
      ul {
        li {
          flex: 0 0 100%;
          max-width: unset;
        }
      }
    }
}