.mobile-navigation-container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: sticky;
	bottom: 0;
	background-color: white;
	z-index: 9999;
	//border-top-left-radius: 40px;
	//border-top-right-radius: 40px;
	box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
	-webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
	overflow: hidden;

	@media screen and (min-width: 1024px){
		display: none;
	}

	.navigation-item{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 16px 10px;
		font-size: 0.8rem !important;
		font-family: "BPG Arial Caps", sans-serif;
		font-weight: normal;

		span {
			display: none;
		}

		&:hover{
			background: rgb(255, 194, 34); ;
			transition-duration: 0.4s;
			color: white;
		}

		&.active{
			background: #e8a600;
			transition-duration: 0.4s;
			color: white;
			span {
				display: block;
			}
		}

	}
}