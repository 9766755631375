.horizontal-menu {
  width: 100%;
  margin-bottom: 70px;
  margin-top: -50px;
  position: relative;
  z-index: 9;
  min-height: 50px;


  .hm-box {
    background: #fff;
    border-radius: 33px;
  }

  @media screen and (max-width: 1390px){
    margin-top:0;
    .hm-box {
      border-radius: 0px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

.menu-slider {
  list-style: none;
  margin: 0;
  padding:0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: calc(100% - 20px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px;
  .menu-item {
    flex: 1;
    text-align: center;
    padding-top: 100px;

    //position: relative;
    //display: -webkit-box;
    //display: -ms-flexbox;
    //display: flex;
    a {
      text-decoration: none;
      display: inline-block;
    }
    &:hover {
      cursor: pointer;
      .title {
        color: #066A3D;
      }
    }
  }
  .swiper {
    //overflow: visible;
    padding: 0 25px;
    //padding-bottom: 30px;
  }

  .swiper-pagination-bullets {}
}

  ul {
    list-style: none;
    margin: 0;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    li {
      flex: 1;
      text-align: center;
      padding-top: 100px;

      //position: relative;
      //display: -webkit-box;
      //display: -ms-flexbox;
      //display: flex;
      a {
        text-decoration: none;
        display: inline-block;
      }
      &:hover {
        cursor: pointer;
        .title {
          color: #066A3D;
        }
      }
    }
  }
  .img_tag {
    //height: 122px;
    position: relative;
    img {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      max-width: 100px;
      max-height: 100px;
    }
  }
  .title {
    text-align: center;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    color: #1e1d23;
    display: flex;
    justify-content: center;
    font-family: "BPG Arial Caps", sans-serif;
    span {
      text-decoration: none;
    }
  }
  @media screen and (max-width: 700px){
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    ul{
      flex-wrap:nowrap;
      li{
        min-width: 22% !important;
        flex: 1 0 22.3% !important;
      }
    }
  }


}

@media (max-width: 1200px) {
  .horizontal-menu {
    .img_tag {
      img {
        transform: translateX(-50%) scale(0.9);
      }
    }
  }
}
@media (max-width: 1000px) {
  .horizontal-menu {
    ul {
      li {
        min-width: 25%;
      }
    }
    .img_tag {
      img {
        transform: translateX(-50%) scale(0.9);
      }
    }
  }
}
@media (max-width: 600px) {
  .horizontal-menu {
    ul {
      li {
        flex: 0 0 33.3%;
        margin: 0 10px;
      }
    }
    .img_tag {
      img {
        transform: translateX(-50%) scale(0.8);
      }
    }
  }
}
@media (max-width: 400px) {
  .horizontal-menu {
    ul {
      li {
        flex: 0 0 50%;
      }
    }
    .img_tag {
      img {
        transform: translateX(-50%) scale(0.8);
      }
    }
  }
}
