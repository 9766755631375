.page-title {
  min-height: 150px;
  margin-bottom: 20px;
  h1 {
    font-size: 1.5rem;
  }
}
.prodacts {
  .prodacts-container {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;

    .title {display: none}

    @media(max-width: 1399px){
      max-width: 1150px;

    }
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    h3 {
      font-family: 'BPG Arial Caps',sans-serif;
      margin: 0;
      font-size: 1.35rem;
      text-indent: 20px;
    }
  }
}
.prodacts-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .card-container {
    overflow: hidden;
    flex-basis: calc(33.33333333333333% - 10px);
    max-width: 33.33333333333333%;
    margin: 5px;
    //aspect-ratio: 0.86;
    //.product-caption {
    //  aspect-ratio: 2;
    //}

    .product-caption {
      h3 {
        a {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    @media(max-width: 1199px){
      flex-basis: calc(50% - 10px);
      max-width: 50%;

      .product-caption {
        h3 {
          a {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .short-description {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @media(max-width: 991px){
      flex-basis: calc(100% - 10px);
      max-width: 100%;

    }

  }
}



.products_container {
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  .prodacts {
    flex: 1;
    width: 100%;
  }
  .products_right {
    width: 300px;
    min-width: 300px;
    margin-left: 30px;
    .product_categories {
      padding: 8px;
      border: 1px solid #e5e5e5;
      border-radius: 20px;
      margin-top: 5px;
      overflow-y: scroll;
      max-height: calc(100vh - 400px);
      h3 {
        font-size: 20px;
        padding: 10px 20px;
        padding-bottom: 10px;
        font-family: "BPG Arial Caps", sans-serif;
      }
      ul {
        margin-bottom: 0;
        border-radius: 16px;
        background-color: #fbf7e8;
        list-style: none;
        padding: 0;
        li {
          padding: 0 20px;
          margin-bottom: 0;
          border-radius: 8px;
          margin-top: -2px;
          position: relative;

          a {
            padding: 0.7em 1em 0.7em 0;
            display: block;
            border-width: 0 0 1.6px;
            border-color: #e5e5e5;
            border-style: dashed;
            color: #808080;
            line-height: 24px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: "BPG Arial Caps", sans-serif;
            i {
              width: 27px;
              height: 27px;
              z-index: 9;
              position: relative;
              background-position: center !important;
              background-size: 27px !important;
              margin-right: 8px;
              transform: scale(1.5);
              background-position-Y: -6px !important;
            }
          }
          > .count {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
          &:last-child {
            a {border: none}
          }

          &.active {
            background: #ffc222;
            a {
              color: #000;
            }
          }

          &:hover {
            background: #ffc2221a;
            cursor: pointer;
            a {
              color: #000;
            }
            &.active {
              background: #ffc222;
            }
          }

        }
      }
    }

  }
}


@media(max-width: 991px){
  .products_right {
    display: none;
  }
  .prodacts-wrap .card-container {
    flex-basis: calc(33.33333% - 10px);
    max-width: 33.33333%;
  }
}
@media(max-width: 767px){
  .prodacts-wrap .card-container {
    flex-basis: calc(50% - 10px);
    max-width: 50%;
  }
}
@media(max-width: 540px){
  .prodacts-wrap .card-container {
    flex-basis: calc(50% - 10px);
    max-width: 50%;
  }
}
@media(max-width: 410px){

  .prodacts-wrap .card-container {
    flex-basis: calc(100% - 10px);
    max-width: 100%;
  }
}
