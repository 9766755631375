


.sign-in-modal {
  font-family: 'BPG Arial Caps',sans-serif;
  .modal-header {
    background: #d8d8d8;
  }
  .form-box {
    margin-bottom: 10px;
    span {
      font-size: 12px;
      color: #626060;
      &:hover {
        cursor: pointer;
        color: #b79400;
      }
    }
  }
  .btn {
    font-size: 14px;
    padding: 4px 12px;
  }
  .input-label {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s ease-in-out;

    label {
      position: absolute;
      top:50%;
      left: 18px;
      transform: translateY(-50%);
      font-size: 14px;
      margin: 0;
      transition: all .2s ease-in-out;
    }
    input {
      padding-top: 16px;
      outline: none;
      background-color: #f4f4f4;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        + label {
          top: 27%;
          left: 13px;
          font-size: 12px;
        }
      }
      &[value]:not([value=""]) {
        + label {
          top: 27%;
          left: 13px;
          font-size: 12px;
        }
      }
    }


  }
}