.reservation-modal {
  .modal-header {
    font-family: "BPG Arial Caps", sans-serif;
    .modal-title {
      font-size: 18px;
    }
  }
  .reservation_wrapper {
    padding: 20px;
    p {
      font-family: "DejaVu Sans", sans-serif;
      margin: 0;
      color: #8A8787;
      padding-left: 15px;
    }

    input {
      border: 1px solid #C1C1C1;
      border-radius: 26px ;
      color: #000000;
      font-size: 16px;
      padding: 8px 12px;
      outline: none;
    }

    .guest_count {
      p {margin: 0}

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        li {
          border: 1px solid #ccc;
          border-radius: 26px !important;
          text-align: center;
          margin: 5px;
          padding: 5px;
          min-width: 47px !important;
          height: 40px !important;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            background: #ffb404;
            border: 1px solid #ffd660;
          }
          &:hover {
            cursor: pointer;
            background: #ffe9bf;
            border: 1px solid #ffd660;
            &.active {
              background: #ffb404;
              border: 1px solid #ffd660;
            }
          }
        }
      }
    }
    .chose_area {
      p {
        margin: 0;
      }
      .btn-area {
        button {
          padding: 10px 5px;
          border: 1px solid #C1C1C1;
          margin: 5px 10px 5px 0;
          background-color: transparent;
          font-family: "BPG Arial Caps", sans-serif;
          font-size: 14px;
          border-radius: 26px;
          min-width: 145px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &.active {
            background: #ffb404;
            border: 1px solid #ffb404;
            color: #000;
          }
          &:hover {
            cursor: pointer;
            background: #ffe9bf;
            &.active {
              background: #ffb404;
            }
          }
        }
      }

    }
    .comment {
      border: 1px solid #C1C1C1;
      border-radius: 26px;
      outline: none;
      padding: 8px 16px;
      font-size: 14px;
    }
    .rw-flex {
      display: flex;
      flex-direction: row;
      .input-label {padding-right: 20px}
    }
  }
  .modal-body {
    .res-btn {
      display: flex;
      justify-content: right;
      button {
        margin-left: 15px;
        min-width: 150px;
        font-size: 16px;
        padding: 10px 0;
        color: #fff;
        border: none;
        border-radius: 10px;
        &.btn-secondary {
          background: #A6AEB5;
          &:hover {
            background: #90989e;
          }
        }
      }
    }
    button {
      font-family: "BPG Arial Caps", sans-serif;
    }
  }
}
