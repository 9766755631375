.slider {
  width: 100%;
  height: 35vw;
  max-height: 450px;
  .border {
    top:0;
    position: absolute;
    width: calc(100% - 60px);
    height:calc(100% - 60px);
    border: 3px solid #ffffff24 !important;
    margin: 30px;
    box-shadow: 0 0 32px #000;
  }
  .item {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  .swiper-slide {
    background-size: cover;
    background-position: center !important;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 2px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: #fff;
    font-size: 22px;
  }

  .beertime-slider{
    height: 100%;
    max-width: 1920px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    .swiper-pagination{
      text-align: center;
      bottom: 60px;
      .swiper-pagination-bullet {
        opacity: 1;
        background-color: #fff;
        &.swiper-pagination-bullet-active {
          background-color: #00a34e;
        }
      }
    }
  }
  @media screen and (max-width: 1390px){
    .beertime-slider{
      .swiper-pagination{
        bottom: 5px;
      }
    }
  }
}