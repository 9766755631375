.headerContactPanel{
	position: sticky;
	top:0;
	background-color: #066D3F;
	color: white;
	padding: 5px;
	display: flex;
	z-index: 999;
	.hc_wrapper {
		display: flex;
		flex-direction: row;

		.left_side {
			flex: 1;
			display: flex;
			align-items: center;
			font-family: "BPG Arial Caps", sans-serif;
			font-weight: normal;
			font-size: 12px;
			span {
				padding-right: 30px;
				i {
					padding-right: 10px;
				}
			}
		}
		.right_side {
			a {
				margin: 0 5px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}


	@media screen and (max-width: 900px){
		display: none;
	}


	.headerContactPanelContainer{
		display: flex;
		flex:1;
		flex-direction: row !important;
		justify-content: space-between;
		font-size: 0.8rem;
		.col {
			font-family: 'DejaVu Sans',sans-serif;
		}
	}
}