
.profile-page{
  max-width: 500px;
  margin: 0 auto;

  .profile_box {
    border: 1px solid #16d123;
    border-radius: 40px;
    box-shadow: 0px 15px 17px #0000003b;
    padding: 0 30px;
  }

  .profile-form {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 50px;

    .form-box {
      margin-bottom: 20px;
      span {
        font-size: 12px;
        color: #626060;
        &:hover {
          cursor: pointer;
          color: #b79400;
        }
      }
    }
    .input-label {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all .4s ease-in-out;

      label {
        position: absolute;
        top:50%;
        left: 18px;
        transform: translateY(-50%);
        font-size: 14px;
        margin: 0;
        transition: all .2s ease-in-out;
      }
      input {
        outline: none;
        background-color: #ffffff !important;
        text-indent: 6px;
        border-radius: 50px;
        padding: 0.7rem 1.1rem;


        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          box-shadow: none;
          outline: none;
          + label {
            top: 4%;
            left: 20px;
            background-color: #fff;
            padding: 0 5px;
          }
        }
        &[value]:not([value=""]) {
          + label {
            top: 4%;
            left: 20px;
            background-color: #fff;
            padding: 0 5px;
          }
        }
      }


    }
    .btn_box {
      display: flex;
      flex-direction: row;
      justify-content: center !important;

      .btn {
        font-size: 18px;
        padding: 10px 12px;
        border-radius: 30px;
        flex: 1;
        white-space: nowrap;
      }
    }
  }

  .profile_avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .avatar {
      width: 90px;
      height: 90px;
      border-radius: 50px;
      margin-bottom: 10px;
      background-size: cover !important;
      background-position: center !important;
    }
  }

  h4 {font-family: "BPG Arial Caps", sans-serif;}

  .form-box {
    margin-bottom: 20px;
    .form-label {
      font-family: "DejaVu Sans", sans-serif;
      margin-bottom: 0;
    }
  }

  .action_button {
    justify-content: space-between;
    margin-top: 1rem;

    button {
      font-family: "BPG Arial Caps", sans-serif;
      font-size: 13px;
    }
  }
  .change_avatar {
    cursor: pointer;
    font-family: "BPG Arial Caps", sans-serif;
    font-size: 12px;
  }
}
