.modal {

  /**.dark {
   --background: var(--bs-lighter);
   --background-accent: #3f4149;
   --text: var(--bs-blue-gray);
 }

 *.light {
   --background: #fff;
   --background-accent: transparent;
   --text: #333d47;
 }

 $background: var(--background);
 $backgroundAccent: var(--background-accent);
 $text: var(--text);
 */
  background-color: white !important;
    z-index: 9999 !important;
  .header {
    /*color: #fff;*/
    color: var(--text);
    font-size: 1.5rem;
    padding: 1.4rem 1rem 1.2rem 2.4rem;

    h1 {
      font-size: 1.8rem;
      margin-bottom: 0;
    }
  }

  .body {
    padding: 2rem 1rem;

    .alert {
      margin: 0.5rem 1.5rem 0 1.5rem;

      p {
        margin-bottom: 0;
        font-size: 1.2rem;
      }
    }

    .form {
      padding: 0 1.5rem;
      margin-top: 1.2rem;

      .formGroup {
        color: var(--text);
        margin-bottom: 2rem;
        position: relative;
        input, select {
          background-color: var(--bs-white);
          font-size: 1.5rem;
          line-height: 3rem;
          color: var(--text);
        }

        label {
          font-size: 1.35rem;
          font-family: lbet-st;
        }

        &[data-required] label:after {
          color: var(--bs-primary);
          margin-left: 0.3rem;
          content: '*';
        }
      }
    }

    .tabs {
      margin-top: 1rem;

      button {
        font-family: lbet-mt-regular;
        font-size: 1.6rem;
        background-color: transparent;
        border: none;
        /*color: #9C9C9C;*/
        color: rgba(255, 255, 255, 0.7);
        border-bottom: 0.4rem solid transparent;

        &:hover {
          border-bottom-color: rgba(255, 255, 255, 0.7);
        }

        &[aria-selected="true"] {
          color: #fff;
          border-bottom-color: var(--bs-primary);
          background-color: transparent;
        }
      }

      border-bottom: none;
      margin-bottom: 1.5rem;
    }
  }

  .footer {
    border-top: 0;
    padding: 0 1.5rem 1rem;
  }


  .buttonsContainer {
    justify-content: flex-end;
  }
  &.dark {
    background-color: var(--bs-lighter);

    --background: var(--bs-lighter);
    --background-accent: #3f4149;
    --text: var(--bs-blue-gray);
  }

  &.light {
    background-color: #fff;

    --background: #fff;
    --background-accent: transparent;
    --text: #333d47;
  }




}
