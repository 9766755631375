.footer{
  background-color: #181818;
  @media screen and  (max-width: 1024px){
    display: none;
  }
  .footer-container{
    padding: 40px 0;
    .footer-info{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h5{
        color: white;
        font-family: 'BPG Arial Caps',sans-serif;
      }
      span{
        color: rgb(176, 176, 176);
        font-family: 'DejaVu Sans',sans-serif;
      }
    }
  }

}
.footer-area {
  width: 100%;
  padding: 10px;
  background: #066A3D;
  @media screen and  (max-width: 1024px){
    display: none;
  }
  .container {
    display: flex;
    align-items: center;
    min-height: 50px;
    flex-direction: row;
    justify-content: space-between;
  }
  .copyright-bar {
    color: white;
    font-size: 0.8rem;
    a {

      color: #ffc222;
      &:hover {
        cursor: pointer;
      }
    }
  }
}