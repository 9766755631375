.hstack {
	flex-wrap: wrap;
}
.page-title {
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-size: cover !important;

	padding: 2.2906835em 0;
	margin: 0 0 5.9968353111em;
	min-height: 240px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	background: #f7f4ef;
	h1{
		font-family: "BPG Arial Caps", sans-serif;
		font-weight: bold;
		text-align: center;
	}
}
.contact-container{
	//padding: 50px 0;
	padding-bottom: 50px;
	.contact-icon-container{
		margin-right: 25px;
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-size: cover !important;
		background-position: center center !important;
		border-radius: 5px;
		flex:1;
		align-content: center;
		iframe {
			width: 100%;
		}
		.contact-icon{
			font-size: 3rem;
			color: rgb(255, 194, 34);;
			margin-bottom: 1.8rem;

		}

	}
	h3 {
		font-family: "BPG Arial Caps", sans-serif;
	}
	p {
		color: gray;
		font-family: "DejaVu Sans", sans-serif;
	}

	form {
		font-family: "DejaVu Sans", sans-serif;
		button {
			font-family: "BPG Arial Caps", sans-serif;
		}
		h3 {
			margin-bottom: 30px;
		}
	}

}

@media(max-width: 767px){
	.contact-container  {

		h3 {
			text-align: center;
			&+p {text-align: center}
		}
		.contact-icon-container {
			flex-direction: row;
			.contact-icon {
				margin-right: 20px;
			}
		}

		.contact-form {
			flex-direction: column;
			.form-box {
				order: 1;
				flex-direction: column;
				margin: 0;
				width: 100%;
				margin-bottom: 40px;
				h3 {
					margin: 40px 0;
				}
			}
			.map {
				order: 2;
				margin: 0;
				width: 100%;
			}
		}
	}

}