.reservation-modal {
  margin-bottom: 60px;
  .modal-header {
    font-family: "BPG Arial Caps", sans-serif;
    .modal-title {
      font-size: 18px;
    }
  }
  /*.reservation_wrapper {
    padding: 20px;
    p {
      font-family: "DejaVu Sans", sans-serif;
      margin: 0;
    }
    .guest_count {
      p {margin: 0}

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        li {
          border: 1px solid #ccc;
          border-radius: 5px;
          text-align: center;
          margin: 5px;
          padding: 5px;
          min-width: 40px;
          height: 36px;
          &.active {
            background: #ffb404;
            border: 1px solid #ffd660;
          }
          &:hover {
            cursor: pointer;
            background: #ffe9bf;
            border: 1px solid #ffd660;
            &.active {
              background: #ffb404;
              border: 1px solid #ffd660;
            }
          }
        }
      }
    }
    .chose_area {
      p {
        margin: 0;
      }
      button {
        margin: 5px 10px 5px 0;
        background-color: #dbdbdb;
        font-family: "BPG Arial Caps", sans-serif;
        padding: 8px 15px;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        &.active {
          background: #ffb404;
        }
        &:hover {
          cursor: pointer;
          background: #ffe9bf;
          &.active {
            background: #ffb404;
          }
        }
      }
    }
    .rw-flex {
      display: flex;
      flex-direction: row;
      .input-label {padding-right: 20px}
    }
  }*/
  .modal-body {
    button {
      font-family: "BPG Arial Caps", sans-serif;
    }
  }
}
