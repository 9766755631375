.product-slider{
		margin-bottom: 30px;
		.product-slider-container{
			max-width: 1380px;
			display: flex;
			margin-right: auto;
			margin-left: auto;
			position: relative;
			flex-direction: column;

			.title {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				h3 {
					font-family: 'BPG Arial Caps',sans-serif;
					font-size: 1.35rem;
					text-indent: 20px;
				}
				span {
					font-family: "BPG Nino Mtavruli";
					font-size: 1rem;
				}
			}
		.beertime-slider{
			height: 100%;
			max-width: 1380px;
			display: flex;
			margin-right: auto;
			margin-left: auto;
			position: relative;
			padding-bottom: 20px;
			.swiper-wrapper {
				height: unset;
			}
			.swiper-pagination{
				text-align: right;
			}
		}
	}

}
.swiper-button-prev{
	color: rgb(6, 109, 63);
}
.swiper-button-next{
	color: rgb(6, 109, 63);;
}
.swiper-pagination-bullet-active{
	background-color: rgb(6, 109, 63);;
}
