.header {
  width: 100%;
  padding: 0 20px;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 99;
  box-shadow: 0 0 30px 0 #0000001a;


  @media screen and (min-width: 1024px){
    top: 34px;
  }


  .header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    &.header-web{
      @media screen and (max-width: 1024px){
         display: none;
      }
    }
    &.header-mob{

      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (min-width: 1024px){
        display: none;
      }
      i{
        font-size: 1.6rem;
      }

      .user {
        margin-right: -8px;
        button {
          border-radius: 30px;
          line-height: normal !important;
          padding: 5px 15px;
          color: #fff;
          font-family: "BPG Arial Caps", sans-serif;
          border: 1px solid #00a14d;
          background-color: #00a14d;
          font-size: 14px;
        }
      }
    }
    .logo {
      a {
        text-decoration: none;
        color: #000;
        font-size: 30px;
        font-weight: 700;
        font-family: cursive;
      }
    }

    nav {
      flex:1;
      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        li {
          a {
            text-decoration: none;
            padding: 1em;
            color: #000;
            font-weight: 700;
            font-size: 16px;
            font-family: 'BPG Arial Caps',sans-serif;
          }
          &:hover {
            cursor: pointer;
            a {
              color: #066A3D;
            }
          }
        }
      }
    }
    ul.widget-container {
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 14px 0;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        //width:  44px;
        //height: 44px;
        //border: 1px solid #e5e5e5;
        //border-radius: 50px;
        margin-left: 10px;

        //img {
        //  width: 14px;
        //}
        //&.registration {
        //  img {    width: 19px;}
        //}

        button {
          border-radius: 30px;
          line-height: normal!important;
          padding: 10px 20px;
          width: 100%;
          color: #fff;
          background-color: transparent;
          outline: none;
          font-family: "BPG Arial Caps", sans-serif;
          font-weight: normal;
          border:1px solid #ccc;
        }
        &.orderBtn {
          button {
            background-color: #00a14d;
            border-color: #00a14d;
            &:hover {
              cursor: pointer;
              background-color: #03b85a;

            }
          }
        }
        &.signInBtn {
          button {
            background-color: #a6a6a6;
            border-color: #a6a6a6;
            &:hover {
              cursor: pointer;
              background-color: #c2c1c1;
              border-color: #c2c1c1;


            }
          }
        }
        &.registrationBtn {
          button {
            //background-color: #6f9a37;
            border:1px solid #ccc;
            color: #000;
            &:hover {
              cursor: pointer;
              background-color: #ffc222;
              border-color: #e5be58;

            }
          }
        }

        //&:hover {
        //  button {
        //    cursor: pointer;
        //    filter: brightness(100%);
        //  }
        //}

        //&:hover {
        //  background: #eeac00;
        //  border-color: #eeac00;
        //  cursor: pointer;
        //}
      }
    }
  }
  .user-widget-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .user-widget-container-btn{
       border-radius: 50%;
      padding: 10px;
      width: 50px;
      aspect-ratio: 1;
      margin-left: 10px;
      position: relative;
      i[count] {
        position: absolute;
        right: -4px;
        top: -5px;
        width: 22px;
        height: 22px;
        background: #ffc107;
        border-radius: 50px;
        &:before {
          position: absolute;
          width: 100%;
          height: 100%;
          content: attr(count);
          left: 0;
          top:0;
          font-size: 12px;
          color:#000;
          line-height: 22px;
        }
      }
      .cart {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        background: #ffffff;
        width: 350px;
        max-height: 500px;
        border-radius: 10px;
        box-shadow: 0 0 10px #000000;
      }
      &:hover{
         background-color: rgb(255, 194, 34);
         border-color: rgb(255, 194, 34);
      }
    }
    .user_box {
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      .user_avatar {
        width: 50px;
        height: 50px;
        background-size: cover !important;
        border-radius: 50px;
        background-color: #ccc !important;
      }
      .user_info {
        flex: 1 1;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #000;
        p {
          margin: 0;
          font-weight: bold;
        }
        span {
          font-size: 12px;
          font-family: "BPG Arial Caps", sans-serif;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .user_drop_down_menu {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      background: #ffffff;
      padding: 10px 15px;
      width: 220px;
      min-height: 150px;
      border-radius: 6px;
      box-shadow: 0px 0px 15px rgba(0,0,0,0.45);

      ul {
        padding-left: 0;
        list-style: none;
        li {
          margin: 0;
          justify-content: left;
          a {
            color: #4f4f4f;
            list-style: none;
            text-decoration: none;
            font-family: "DejaVu Sans", sans-serif;
            font-size: 14px;
            padding: 5px;

            span {padding-left: 10px}

            .cart_icon {
              position: relative;
              display: inline-block;

              i {
                position: absolute;
                top: -3px;
                left: -5px;
                width: 14px;
                height: 14px;
                border-radius: 10px;
                background: #ff0d00bd;

                &:before {
                  position: absolute;
                  content: attr(count);
                  width: 90%;
                  height: 100%;
                  color: #fff;
                  font-size: 10px;
                  text-align: center;
                  line-height: 16px;
                }
              }
            }

            &:hover {
              color: #000;
              cursor: pointer;
            }

          }
          .exit {
            background: #b2b2b2;
            width: 100%;
            text-align: center;
            border-radius: 3px;
            margin-top: 10px;
            color: #000;
            span {padding: 0;}
            &:hover {
              cursor: pointer;
              background: #c93838;
              color: #fff;
            }
          }
        }
      }
    }

  }
}

.mob_nav {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  top:0;
  left: 0;
  .mob_nav_back {
    position: absolute;
    top:0;
    left: 0;
    background: #00000063;
    width: 100%;
    height: 100%;
  }
  .mob_nav_list {
    position: absolute;
    top:0;
    left: 0;
    width: 80%;
    max-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .mob_nav_head {
      display: flex;
      flex-direction: row;
      height: 77px;
      align-items: center;
      padding: 0 20px;
      .logo {
        flex: 1;
        a {
          text-decoration: none;
          color: #ffffff;
          font-size: 30px;
          font-weight: 700;
          font-family: cursive;
        }
      }
      .mob_nav_close {
        text-decoration: none;
        color: #ffffff;
        font-size: 25px;
        font-weight: 700;
        font-family: cursive;
      }

    }

    ul {
      list-style: none;
      margin: 0;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ffb750;
      padding-bottom: 10px;
      margin-bottom: 20px;
      flex: 1;
      font-family: "DejaVu Sans", sans-serif;
      li {
        display: block;
        a {
          text-decoration: none;
          color: #ffffff;
          padding: 5px 0;
          display: inline-block;
        }
      }
    }
  }
  .user_box {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    .user_avatar {
      width: 50px;
      height: 50px;
      background-size: cover !important;
      border-radius: 50px;
      background-color: #ffffff !important;
    }
    .user_info {
      flex: 1 1;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      p {
        margin: 0;
        font-weight: bold;
      }
      span {
        font-size: 12px;
        font-family: "BPG Arial Caps", sans-serif;
      }
    }
  }
  .mob_nav_footer {
    padding: 20px;

    button {
      border: none;
      background: #ffffff00;
      font-size: 14px;
      font-family: "BPG Arial Caps", sans-serif;
      padding: 5px 15px;
      border-radius: 3px;
      color: #fff;
    }
  }


}

.language {
  display: flex;
  [data-lang] {
    transition: all 0.2s ease-in-out;
    display: inline-block;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-radius: 50px;
    position: relative;
    height: 30px;
    width: 30px;
    &:hover {
      transform: scale(1);
      transition: all 0.1s ease-in-out;
    }
  }
  a[data-lang="ka"] {
    background: url('../../assets/flags/ka.png');
  }
  a[data-lang="ru"] {
    background: url('../../assets/flags/ru.png');
  }
  a[data-lang="en"] {
    background: url('../../assets/flags/en.png');
  }
}

.qr_modal {
  padding: 0 !important;
  .modal-dialog {
    max-width: 390px;
    margin: 0.5rem auto;
  }
  .modal-header {
    border-bottom: none;
    padding: 1rem 26px;
  }
  .qr_bg {
    background: url('../../assets/img/qr_bg.png') no-repeat;
    width: 100%;
    max-width: 338px;
    margin: 0 auto;
    box-shadow: 0 0 8px #00000094;
    border-radius: 40px;
    padding: 10px;
    .qr_bg_code {
      width: 220px;
      height: 220px;
      margin: 60px auto;
      background: #fff;
      padding: 10px;
      border-radius: 8px;
    }
    .qr_username {
      color: #fff;
      padding-left: 20px;
      text-transform: capitalize;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      p {

        font-size: 20px
      }
    }
  }
  .qr_percent{
    width: 100%;
    max-width: 338px;
    background: #F5F5F7;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,0.29);
    margin: 0 auto;
    div {
      flex: 1;
      margin: 0;
      text-align: center;
      color: #254741;
      font-weight: bold;
      font-family: DejaVu Sans,sans-serif;
      &:first-child {
        border-right: 2px solid #F1A93B;
      }
    }
  }
  .qr_cashback {
    width: 100%;
    max-width: 338px;
    background: #F5F5F7;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,0.29);
    margin: 30px auto;
    div {
      flex: 1;
      margin: 0;
      text-align: center;
      color: #254741;
      font-weight: bold;
      font-family: DejaVu Sans,sans-serif;
      &:first-child {
        border-right: 2px solid #F1A93B;
      }
    }
  }

  .modal-title {
   font-family: "BPG Arial Caps", sans-serif;
 }
  .modal-body {
    text-align: center;
    padding-bottom: 50px;
    > div {
      font-family: "BPG Arial Caps", sans-serif;
      padding-bottom: 20px;
    }
  }
  /*@media (max-width: 340px) {
    .qr_bg {
      background-color: #254741;
    }
  }*/
}

.cart_modal {
  .modal-title {
    font-family: "BPG Arial Caps", sans-serif;
  }
  .modal-body {
    padding-bottom: 15px;
  }
  .cf_button {text-align: center}
}
.mob_cart_icon{

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding:0.1rem;
  margin-right: 0.4rem;
  .mob_cart_icon_item{
    width: 1.6rem !important;
    height: 1.6rem !important;
     svg{
       vertical-align: top;
     }
  }
}