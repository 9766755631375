.container {
  h1 {
    font-size: 1.3rem;
    font-family: "BPG Arial Caps", sans-serif;
  }
  h4 {
    font-family: "DejaVu Sans", sans-serif;
  }

  .similar_dishes {
    h3 {
      font-family: "BPG Arial Caps", sans-serif;
    }
    .product-caption {
      h3 {
        a {
          font-family: "DejaVu Sans", sans-serif;
          color: #000;
        }
      }
    }
  }

  .product_wrap {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    min-height: calc(100vh - 380px);
    .prod_box {
      flex: 1 1;
      padding: 0 8px;
      &:first-child {max-width: 500px;}
      h1 {margin-top: 10px;}
    }
    .product_prize {
      h4 {
        color: #000;
        font-weight: bold;
      }
    }
  }

  .product-method-wrapper {
    display: flex;
  }
  .product_count {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 50px;

    button {
      background: #f7f4ef;
      width: 30px;
      height: 30px;
      /* line-height: 8px; */
      outline: none;
      border-radius: 50px;
      padding: 0;
      /* line-height: 15px; */
      font-weight: bold;
      display: flex;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-color: #ffc107;
      }
    }
    span {
      width: 34px;
      text-align: center;
    }

  }
  .heart-btn {
    margin-left: 10px;
    background-color: #f7f4ef;
    border-radius: 8px;
  }
  .product-card-button {
    display: flex;
    flex: 1;
    max-width: 300px;
    justify-content: flex-end;
    img {filter: brightness(0);}
    button {
      width: 100%;
      transition: unset;
      font-family: "BPG Arial Caps", sans-serif;
      span {
        margin-left: 5px;
      }
      &:hover {
        color:#fff;
        img {
          filter: brightness(2);
        }

      }
    }
  }
  .product_soc {
    padding: 15px 0px;
    p {
      font-size: 13px;
      &:last-child {margin-bottom: 0px}
    }
    span {
      margin-left: 10px;
      i {
        color: #888686;
      }
      &:hover {
        cursor: pointer;
        i {
          color: #ffc107;
        }
      }
    }
    a {font-weight: bold}
  }

  .product_info {
    margin: 10px 0;
    margin-left: 20px;
    color: #888686;
  }

}

@media(max-width: 991px){
  .container .product_count {
    margin-right: 10px;
  }
}

@media(max-width: 767px){
  .container {
    h1 {
      font-size: 1.4rem;
    }
    .product_wrap {
      flex-direction: column;
    }
    .product_info {
      margin-left: 0;
    }
    .product_payment {
      span {
        margin: 0 !important;
      }
    }
  }
}

@media(max-width: 370px){
  .product-card-button {
    span {display: none}
    img {filter: brightness(0);}
  }
  .product_payment {
    img {width: 100%}
  }
}