@font-face {
  font-family: "BPG Nino Mtavruli";
  src: url("./bpg-nino-mtavruli-webfont.eot");
  /* IE9 Compat Modes */
  src: url("./bpg-nino-mtavruli-webfont.eot?#iefix") format("embedded-opentype"), url("./bpg-nino-mtavruli-webfont.woff2") format("woff2"), url("./bpg-nino-mtavruli-webfont.woff") format("woff"), url("./bpg-nino-mtavruli-webfont.ttf") format("truetype"), url("./bpg-nino-mtavruli-webfont.svg#bpg_nino_mtavruliregular") format("svg");
  /* Legacy iOS */
}

/*# sourceMappingURL=font.css.map */


@font-face {
  font-family: 'DejaVu Sans';
  src: url('./dejavu-sans/dejavu-sans-webfont.eot'); /* IE9 Compat Modes */
  src: url('./dejavu-sans/dejavu-sans-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./dejavu-sans/dejavu-sans-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('./dejavu-sans/dejavu-sans-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('./dejavu-sans/dejavu-sans-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./dejavu-sans/dejavu-sans-webfont.svg#dejavu_sansregular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'BPG Arial Caps';
  src: url('./bpg-caps/bpg-arial-caps-webfont.eot'); /* IE9 Compat Modes */
  src: url('./bpg-caps/bpg-arial-caps-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./bpg-caps/bpg-arial-caps-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('./bpg-caps/bpg-arial-caps-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('./bpg-caps/bpg-arial-caps-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./bpg-caps/bpg-arial-caps-webfont.svg#bpg_arial_capsregular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'BPG Nino Elite Exp';
  src: url('./bpg-nino-elit/bpg-nino-elite-exp-webfont.eot'); /* IE9 Compat Modes */
  src: url('./bpg-nino-elit/bpg-nino-elite-exp-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./bpg-nino-elit/bpg-nino-elite-exp-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('./bpg-nino-elit/bpg-nino-elite-exp-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('./bpg-nino-elit/bpg-nino-elite-exp-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./bpg-nino-elit/bpg-nino-elite-exp-webfont.svg#bpg_nino_elite_expregular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'FiraGO';
  src: url('./firago_roman/FiraGO-Bold.eot') format('eot'),
  url('./firago_roman/FiraGO-Bold.woff') format('woff'),
  url('./firago_roman/FiraGO-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
