
.order-page {
  h3 {
    font-family: "BPG Arial Caps", sans-serif;
  }
  .notification-title {
    font-family: "BPG Arial Caps", sans-serif;
  }
  .notification-description {
    font-family: "DejaVu Sans", sans-serif;
  }
  .notification {
    &:hover {
      background: #f3f3f3;
    }
  }
}
.order-icon{
  bottom:300px;
  display: none;
  @media screen and (max-width: 1024px) {
    bottom:100px;
    display: block;

  }



}