
.notification-page {
  min-height: 500px;
  h3 {
    font-family: "BPG Arial Caps", sans-serif;
  }
  .notification-title {
    font-family: "BPG Arial Caps", sans-serif;
  }
  .notification-description {
    font-family: "DejaVu Sans", sans-serif;
  }
  .notification {
    &:hover {
      background: #f3f3f3;
    }
  }

  .notify-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media(max-width: 500px){
  .notification-page {
    .notify-head {
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding-bottom: 10px;
      .notification-title {
        font-weight: bold;
      }
    }
  }
}