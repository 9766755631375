.orderDetails {
  .modal-header {
    font-family: "BPG Arial Caps", sans-serif;
    .modal-title {
      font-size: 18px;
    }
  }
  .modal-body {
    .res-btn {
      display: flex;
      justify-content: right;
      button {
        margin-left: 15px;
        min-width: 150px;
        font-size: 16px;
        padding: 10px 0;
        color: #fff;
        border: none;
        border-radius: 10px;
        &.btn-secondary {
          background: #A6AEB5;
          &:hover {
            background: #90989e;
          }
        }
      }
    }
    button {
      font-family: "BPG Arial Caps", sans-serif;
    }

	.order_date{
		display: flex;
		flex-direction: row;
		justify-content: space-between;

	}
	  .order_guest{
		  display: flex;
		  flex-direction: row;
		  justify-content: space-between;
	  }
	.order_details{
		>div{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
	.order_comment{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.order_contact{
		text-decoration: none;
	}

  }
}
