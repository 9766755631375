@import './assets/fonts/_font.css';
@import "../node_modules/bootstrap/scss/bootstrap.scss";


* {
  box-sizing: border-box;
}
.mw {
  max-width: 1380px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.menu-btn {
  text-align: center;
  //margin-top: -28px;
  position: relative;
  z-index: 9;
  margin-bottom: 20px;
  a{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1em;
    color: var(--e-global-color-accent);
    background-color: #ffc222;
    border-radius: 8px 8px 8px 8px;
    padding: 20px 45px;
    display: inline-block;
    text-decoration: none;
    &:hover {
      color: #fff;
      background-color: #eeac00;
    }
  }
}
.notifications-container{
  padding-bottom: 50px;
  .notification{
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(128, 128, 128, 0.11);
    padding: 10px 20px;
    cursor: pointer;
    &.read{
      color: #7c7c7c;
    }
    &.unread{

    }
    .notification-description{

    }
  }
}
.container{
  height: 100%;
}

.sign-up-modal {
  font-family: 'BPG Arial Caps',sans-serif;
  .modal-header {
    background: #d8d8d8;
  }
  .form-box {
    margin-bottom: 10px;
    span {
      font-size: 12px;
      color: #626060;
      &:hover {
        cursor: pointer;
        color: #b79400;
      }
    }
  }
  .btn {
    font-size: 14px;
    padding: 4px 12px;
  }
  .input-label {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s ease-in-out;

    label {
      position: absolute;
      top:50%;
      left: 18px;
      transform: translateY(-50%);
      font-size: 14px;
      margin: 0;
      transition: all .2s ease-in-out;
    }
    input {
      padding-top: 16px;
      outline: none;
      background-color: #f4f4f4;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        + label {
          top: 27%;
          left: 13px;
          font-size: 12px;
        }
      }
      &[value]:not([value=""]) {
        + label {
          top: 27%;
          left: 13px;
          font-size: 12px;
        }
      }
    }


  }
}

.modal_style {
  font-family: 'BPG Arial Caps',sans-serif;
  .modal-header {
    background: #d8d8d8;
  }
  .form-box {
    margin-bottom: 10px;
    span {
      font-size: 12px;
      color: #626060;
      &:hover {
        cursor: pointer;
        color: #b79400;
      }
    }
    .form-label {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
  .btn {
    font-size: 14px;
    padding: 4px 12px;
  }
  .input-label {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s ease-in-out;

    label {
      position: absolute;
      top:50%;
      left: 18px;
      transform: translateY(-50%);
      font-size: 14px;
      margin: 0;
      transition: all .2s ease-in-out;
    }
    input {
      padding-top: 16px;
      outline: none;
      background-color: #f4f4f4;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        + label {
          top: 27%;
          left: 13px;
          font-size: 12px;
        }
      }
      &[value]:not([value=""]) {
        + label {
          top: 27%;
          left: 13px;
          font-size: 12px;
        }
      }
    }


  }
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 16px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 19px;
  height: 17px;
  transition: all 0.15s linear;
  background-color: #fff;
  &.active {
    background-image: url("./assets/ico/view-on.svg");
    height: 12px;
    top: 19px;
  }
  &.hide {
    background-image: url("./assets/ico/view-off.svg");
  }
}

.sign-up-modal-restyle {
  //font-family: 'BPG Arial Caps',sans-serif;
  font-family: DejaVu Sans,sans-serif;

  .invalid-feedback {
    display: block;
    padding-left: 20px;
    font-size: 12px;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  .form-control:focus {
    outline: none;
    border: 1px solid #ced4da;
    box-shadow: none;
  }

  .toggle-password {
    padding-right: 35px;
  }
  .modal-body {
    padding: 1rem 30px;
  }
  .modal-header {
    border: none;
    padding: 1rem 30px;
  }
  .form-control {
    border-radius: 50px;
    background-color: #fff !important;
  }
  .form-box {
    margin-bottom: 20px;
    span {
      font-size: 12px;
      color: #626060;
      &:hover {
        cursor: pointer;
        color: #b79400;
      }
    }
  }
  .btn_box {
    display: flex;
    flex-direction: row;
    justify-content: center !important;

    .btn {
      font-size: 18px;
      padding: 10px 12px;
      border-radius: 30px;
      flex: 1;
      &:first-child {max-width: 160px}
    }
  }

  .input-label {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s ease-in-out;

    label {
      position: absolute;
      top:50%;
      left: 18px;
      transform: translateY(-50%);
      font-size: 14px;
      margin: 0;
      transition: all .2s ease-in-out;
    }
    input {
      padding-top: 16px;
      outline: none;
      background-color: #f4f4f4;
      text-indent: 6px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        + label {
          top: 4%;
          left: 20px;
          background-color: #fff;
          padding: 0 5px;
        }
      }
      &[value]:not([value=""]) {
        + label {
          top: 4%;
          left: 20px;
          background-color: #fff;
          padding: 0 5px;
        }
      }
    }


  }
}

.sign-in-modal-restyle {
  //font-family: 'BPG Arial Caps',sans-serif;
  font-family: DejaVu Sans,sans-serif;

  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  .form-control:focus {
    outline: none;
    border: 1px solid #ced4da;
    box-shadow: none;
  }

  .toggle-password {
    padding-right: 35px;
  }
  .modal-body {
    padding: 1rem 30px;
  }
  .modal-header {
    border: none;
    padding: 1rem 30px;
  }
  .form-control {
    border-radius: 50px;
    background-color: #fff !important;
  }
  .form-box {
    margin-bottom: 20px;
    span {
      font-size: 12px;
      color: #626060;
      &:hover {
        cursor: pointer;
        color: #b79400;
      }
    }
  }
  .btn_box {
    display: flex;
    flex-direction: row;
    justify-content: center !important;

    .btn {
      font-size: 18px;
      padding: 10px 12px;
      border-radius: 30px;
      flex: 1;
      &:first-child {max-width: 160px}
    }
  }

  .input-label {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s ease-in-out;

    label {
      position: absolute;
      top:50%;
      left: 18px;
      transform: translateY(-50%);
      font-size: 14px;
      margin: 0;
      transition: all .2s ease-in-out;

    }
    input {
      padding-top: 16px;
      outline: none;
      background-color: #f4f4f4;
     // -webkit-text-fill-color: #ffffff !important;
      text-indent: 6px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:-webkit-autofill {
      //  -webkit-text-fill-color: #ffffff !important;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      &:focus {
        + label {
          top: 4%;
          left: 20px;
          background-color: #fff;
          padding: 0 5px;
        }
      }
      &[value]:not([value=""]) {
        + label {
          top: 4%;
          left: 20px;
          background-color: #fff;
          padding: 0 5px;
        }
      }
    }


  }

  .rec-pass {
    padding: 10px 7px;
    display: block;
  }
}

.modal-restyle {
  //font-family: 'BPG Arial Caps',sans-serif;
  font-family: DejaVu Sans,sans-serif;

  .invalid-feedback {
    display: block;
    padding-left: 20px;
    font-size: 12px;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  .form-control:focus {
    outline: none;
    border: 1px solid #ced4da;
    box-shadow: none;
  }

  .toggle-password {
    padding-right: 35px;
  }
  .modal-body {
    padding: 1rem 30px;
  }
  .modal-header {
    border: none;
    padding: 1rem 30px;
  }
  .form-control {
    border-radius: 50px;
    background-color: #fff !important;
  }
  .form-box {
    margin-bottom: 20px;
    span {
      font-size: 12px;
      color: #626060;
      &:hover {
        cursor: pointer;
        color: #b79400;
      }
    }
  }
  .btn_box {
    display: flex;
    flex-direction: row;
    justify-content: center !important;

    .btn {
      font-size: 18px;
      padding: 10px 12px;
      border-radius: 30px;
      flex: 1;
      &:first-child {max-width: 160px}
    }
  }

  .input-label {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s ease-in-out;

    label {
      position: absolute;
      top:50%;
      left: 18px;
      transform: translateY(-50%);
      font-size: 14px;
      margin: 0;
      transition: all .2s ease-in-out;
    }
    input {
      padding-top: 16px;
      outline: none;
      background-color: #f4f4f4;
      text-indent: 6px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        + label {
          top: 4%;
          left: 20px;
          background-color: #fff;
          padding: 0 5px;
        }
      }
      &[value]:not([value=""]) {
        + label {
          top: 4%;
          left: 20px;
          background-color: #fff;
          padding: 0 5px;
        }
      }
    }


  }
}
.cart {

  &.active {
    svg {
      fill: #00a14d !important;
    }

  }
}
.swiper-pagination{
  bottom: -5px !important;
 }

.main-container{
  min-height: calc(100vh - 350px );
  @media screen and (max-width: 1024px){
    min-height: calc(100vh - 77px );
  }
}
